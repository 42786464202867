<template lang="pug">
transition(name="modal")
  .modal-mask
    .modal-wrapper
      .modal-dialog.modal-dialog-scrollable.modal-lg(role="document")
        .modal-content
          .modal-body
            .row.float-right
              .col-12
                a.bg-white.border-none(
                  href="javascript:;"
                  role="button",
                  data-dismiss="modal",
                  aria-label="Close"
                  @click="$emit('close')"
                )
                  div
                    img(:height="18" :width="18" src="@/assets/img/close.png")
            .row.mt-3
              .col-6
                img.matador-border-radius.w-100(v-if="vehicle.image" :src="vehicle.image")
                img.matador-border-radius.w-100(v-if="!vehicle.image" src="@/assets/img/car-placeholder.png")
              .col-6
                .row(v-for="item in vehicle_data")
                  .col-6.col-xl-5
                    label.font-weight-bolder {{ item[0] }}
                  .col-6.col-xl-7
                    .d-flex
                      span.mr-2 :
                      span {{ item[1] }}
                .row.mt-3
                  .col-12
                    .d-flex
                      matador-button.flex-grow-1(@click="$router.push({ path: `dashboard/detail/${vehicle.unit_id}` })") Pergi ke Detail
              .col-12.mt-4
                .matador-tab.d-flex
                  a.font-weight-bolder.w-100.text-center.py-3(
                    href="javascript:;",
                    :class="{ active: modalTabIndex == 0 }",
                    @click="modalTabIndex = 0"
                  ) Informasi Pelacakan
                .p-0.row
                  .border-bottom.py-2.col-6(v-for="column in tabs")
                    .border-bottom(v-for="item in Object.values(column)")
                      .d-flex.py-3.justify-content-between
                        p.font-weight-bolder.m-0 {{ item[0] }}
                        p.m-0.text-right {{ item[1] }}
                        //- strong.ml-auto No Data
</template>

<script>
export default {
  data() {
    return {
      modalTabIndex: 0,
      cols: 2,
    };
  },
  props: ["vehicle", "close", "vehicleDataDetailAll"],
  methods: {
    // Format meters to kilometers with 2 decimal places
    formatDistance(meters) {
      const km = meters / 1000;
      return `${km.toFixed(2)} Km`;
    },
    
    // Format seconds to hours and minutes
    formatOperatingTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      
      if (hours === 0) {
        return `${minutes} mnt`;
      }
      return `${hours} jam ${minutes} mnt`;
    }
  },
  computed: {
    vehicle_data() {
      if (!this.vehicle || !this.vehicle.vin) return [];
      const log = this.vehicle.device_unit.device.last_log_data || {};
      return [
        ["Nomor Polisi", this.vehicle.vin],
        ["Nama Perangkat", this.vehicle.unit_id],
        ["Jenis Kendaraan", this.vehicle.unit_type.vehicle_type.name],
      ];
    },
    tabs() {
      if (!this.vehicle || !this.vehicle.vin) return [];
      const log = this.vehicle.device_unit.device.last_log_data || {};
      const dataVehicle = this.vehicleDataDetailAll || {}
      let current_info = [];
      if (this.modalTabIndex == 0) {
        current_info = [
          ["Nomor Polisi", this.vehicle.vin],
          [
            "Koordinat",
            log.latitude ? log.latitude + " , " + log.longitude : "-",
          ],
          ["Status Daya", dataVehicle.status_daya ? "On" : "Off"],
          /*  ["UUID", this.vehicle.device_unit.device_id], */
          ["Jarak Tempuh Total", this.formatDistance(dataVehicle.total_distance ?? 0)],
          ["Jarak Tempuh Hari Ini", this.formatDistance(dataVehicle.distance_today ?? 0)],
          [
            "Waktu Operasional Hari Ini",
            this.formatOperatingTime(dataVehicle.operating_time),
          ],
          ["IMEI", log.imei ? log.imei : "-"],
          ["Nomor SIM", this.vehicle.device_unit.device.sim_number],
          ["Pemakaian Bahan Bakar Hari ini", dataVehicle.fuel + " L" ?? 0],
          ["Rata-Rata Pemakaian Bahan Bakar Hari ini", dataVehicle.fuel_consumption + " km/L" ?? 0],
          // ["Waktu Mulai Siklus", log.siklus_start],
          // ["Waktu Selesai Siklus", log.siklus_end],
        ];
        // console.log("current Info :",current_info)
      } else if (this.modalTabIndex == 1) {
        current_info = [
          ["Voltmeter", log.voltage ? log.voltage : "-"],
          ["Start Stop Counter", log.start_count ? log.start_count : "-"],
          ["Engine", this.vehicle.unit_type.engine],
          ["Tire Count", this.vehicle.unit_type.tire_count],
          ["Torque", this.vehicle.unit_type.torque],
          [
            "Brand",
            this.vehicle.unit_type.brand_id
              ? this.vehicle.unit_type.brand_id
              : "-",
          ],
          ["Series", this.vehicle.unit_type.series],
          ["Year", this.vehicle.unit_type.year],
          ["Fuel", this.vehicle.unit_type.fuel],
        ];
      } else if (this.modalTabIndex == 2) {
        current_info = [
          ["GSM Signal", log.signal ? log.signal : "-"],
          ["IMEI", log.imei ? log.imei : "-"],
          [
            "Carrier/Mobile Network",
            this.vehicle.device_unit.device.network_provider_id,
          ],
          ["SIM Number", this.vehicle.device_unit.device.sim_number],
          ["Description", this.vehicle.device_unit.device.description],
          ["Data Usage", log.data_usage ? log.data_usage + " MB" : "-"],
          [
            "Device Temperature",
            log.temperature ? log.temperature + " C" : "-",
          ],
        ];
      }
      let columns = [];
      let mid = Math.ceil(current_info.length / this.cols);
      for (let col = 0; col < this.cols; col++) {
        columns.push(current_info.slice(col * mid, col * mid + mid));
      }
      return columns;
    },
  },
};
</script>
