  // import produce from "immer";
  // import { Line, mixins } from "vue-chartjs";
  // import { minifyChartAmount } from "../../utils/utilities";
  // const { reactiveProp } = mixins;

  // export default {
  //   extends: Line,
  //   mixins: [reactiveProp],
  //   props: ["chart-data", "options", "gradients"],
  //   data() {
  //     return {
  //       gradient: null,
  //       gradientOne: null,
  //       gradientTwo: null,
  //     };
  //   },
  //   methods: {
  //     update() {
  //       this.$data._chart.update();
  //     },
  //     create() {
  //       const chartData = this.chartData;

  //       if (this.gradients) {
  //         this.gradientOne = this.gradients[0];
  //         this.gradientTwo = this.gradients[1];
  //         this.gradient = this.$refs.canvas
  //           .getContext("2d")
  //           .createLinearGradient(0, 0, 0, 450);
  //         this.gradient.addColorStop(0, this.gradients[0]);
  //         this.gradient.addColorStop(1, this.gradients[1]);
  //         chartData.datasets = chartData.datasets.map((item) => {
  //           item.backgroundColor = this.gradient;
  //           return item;
  //         });
  //       }

  //       this.renderChart(
  //         chartData,
  //         produce(this.options || {}, (draft) => {
  //           draft.scales = {
  //             yAxes: [
  //               {
  //                 ticks: {
  //                   beginAtZero: false,
  //                   mirror: false,
  //                   callback: function (value) {
  //                     if (value >= 1000000000) return Math.round(value / 1000000000) + " B";
  //                     if (value >= 1000000) return Math.round(value / 1000000) + " M";
  //                     if (value >= 1000) return Math.round(value / 1000) + " K";
  //                     return value + " L"; // Menambahkan "L" pada sumbu Y
  //                   },
  //                 },
  //                 scaleLabel: {
  //                   display: true,
  //                   labelString: "Bahan Bakar (L)", // Menambahkan label pada sumbu Y
  //                   fontColor: "#333",  // Warna teks
  //                   fontSize: 14,       // Ukuran font label
  //                   fontStyle: "bold",  // Gaya teks
  //                 },
  //               },
  //             ],
  //             xAxes: [
  //               {
  //                 scaleLabel: {
  //                   display: true,
  //                   labelString: "Waktu (jam)", // Menambahkan label pada sumbu X
  //                   fontColor: "#333",  // Warna teks
  //                   fontSize: 14,       // Ukuran font label
  //                   fontStyle: "bold",  // Gaya teks
  //                 },
  //               },
  //             ],
  //           };
  //         })
  //       );
  //     },
  //   },
  //   mounted() {
  //     if (this.chartData) {
  //       const chartData = this.chartData;

  //       if (this.gradients) {
  //         this.gradientOne = this.gradients[0];
  //         this.gradientTwo = this.gradients[1];
  //         this.gradient = this.$refs.canvas
  //           .getContext("2d")
  //           .createLinearGradient(0, 0, 0, 450);
  //         this.gradient.addColorStop(0, this.gradients[0]);
  //         this.gradient.addColorStop(1, this.gradients[1]);
  //         chartData.datasets = chartData.datasets.map((item) => {
  //           item.backgroundColor = this.gradient;
  //           return item;
  //         });
  //       }

  //       this.renderChart(
  //         chartData,
  //         produce(this.options || {}, (draft) => {
  //           draft.scales = {
  //             yAxes: [
  //               {
  //                 ticks: {
  //                   beginAtZero: false,
  //                   mirror: false,
  //                   callback: function (value) {
  //                     return value.toFixed(2) + " L";;// Menambahkan "L" pada label sumbu Y
  //                   },
  //                 },
  //                 scaleLabel: {
  //                   display: true,
  //                   labelString: "Bahan Bakar (L)", // Menambahkan label pada sumbu Y
  //                   fontColor: "#333",  // Warna teks
  //                   fontSize: 14,       // Ukuran font label
  //                   fontStyle: "bold",  // Gaya teks
  //                 },
  //               },
  //             ],
  //             xAxes: [
  //               {
  //                 scaleLabel: {
  //                   display: true,
  //                   labelString: "Waktu (jam)", // Menambahkan label pada sumbu X
  //                   fontColor: "#333",  // Warna teks
  //                   fontSize: 14,       // Ukuran font label
  //                   fontStyle: "bold",  // Gaya teks
  //                 },
  //               },
  //             ],
  //           };
  //         })
  //       );
  //     }
  //   },
  // };


  import produce from "immer";
  import { Line, mixins } from "vue-chartjs";
  const { reactiveProp } = mixins;

  export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ["chartData", "options", "gradients"],
    methods: {
      update() {
        this.$data._chart.update();
      },
      create() {
        const chartData = this.chartData;

        if (this.gradients) {
          const ctx = this.$refs.canvas.getContext("2d");
          const gradient = ctx.createLinearGradient(0, 0, 0, 450);
          gradient.addColorStop(0, this.gradients[0]);
          gradient.addColorStop(1, this.gradients[1]);

          chartData.datasets = chartData.datasets.map((dataset) => {
            dataset.backgroundColor = gradient;
            return dataset;
          });
        }

        let maxValue = 400

        this.renderChart(
          chartData,
          produce(this.options || {}, (draft) => {
            draft.scales = {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    max: maxValue,
                    callback: function (value) {
                      return value + " L"; // Tambahkan "L" pada label
                    },
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Bahan Bakar (L)", // Menambahkan label pada sumbu Y
                    fontColor: "#333",
                    fontSize: 14,
                    fontStyle: "bold",
                  },
                },
              ],
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Waktu (jam)", // Menambahkan label pada sumbu X
                    fontColor: "#333",
                    fontSize: 14,
                    fontStyle: "bold",
                  },
                },
              ],
            };
          })
        );
      },
    },
    mounted() {
      if (this.chartData) {
        this.create();
      }
    },
  };
