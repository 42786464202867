// import produce from "immer";
// import { Line, mixins } from "vue-chartjs";
// import { minifyChartAmount } from "../../utils/utilities";
// const { reactiveProp } = mixins;

// export default {
//   extends: Line,
//   mixins: [reactiveProp],
//   props: ["chart-data", "options", "gradients"],
//   data() {
//     return {
//       gradient: null,
//       gradientOne: null,
//       gradientTwo: null,
//     };
//   },
//   methods: {
//     update() {
//       this.$data._chart.update();
//     },
//     create() {
//       const chartData = this.chartData;

//       if (this.gradients) {
//         this.gradientOne = this.gradients[0];
//         this.gradientTwo = this.gradients[1];
//         this.gradient = this.$refs.canvas
//           .getContext("2d")
//           .createLinearGradient(0, 0, 0, 450);
//         this.gradient.addColorStop(0, this.gradients[0]);
//         this.gradient.addColorStop(1, this.gradients[1]);
//         chartData.datasets = chartData.datasets.map((item) => {
//           item.backgroundColor = this.gradient;
//           return item;
//         });
//       }

//       this.renderChart(
//         chartData,
//         produce(this.options || {}, (draft) => {
//           draft.scales = {
//             yAxes: [
//               {
//                 ticks: {
//                   beginAtZero: false,
//                   mirror: false,
//                   callback: function (value) {
//                     if (value >= 1000000000) {
//                       return (value / 1000000000).toFixed(2) + " M";
//                     } else if (value >= 1000000) {
//                       return (value / 1000000).toFixed(2) + " J";
//                     } else if (value >= 1000) {
//                       return (value / 1000).toFixed(2) + " R";
//                     }
//                     return value.toFixed(2) + " L";

//                   },
//                 },
//                 scaleLabel: {
//                   display: true,
//                   labelString: "Jarak Tempuh (m)", // Menambahkan label pada sumbu Y
//                   fontColor: "#333",  // Warna teks
//                   fontSize: 14,       // Ukuran font label
//                   fontStyle: "bold",  // Gaya teks
//                 },
//               },
//             ],
//             xAxes: [
//               {
//                 scaleLabel: {
//                   display: true,
//                   labelString: "Waktu (jam)", // Menambahkan label pada sumbu X
//                   fontColor: "#333",  // Warna teks
//                   fontSize: 14,       // Ukuran font label
//                   fontStyle: "bold",  // Gaya teks
//                 },
//               },
//             ],
//           };
//         })
//       );
//     },
//   },
//   mounted() {
//     if (this.chartData) {
//       const chartData = this.chartData;

//       if (this.gradients) {
//         this.gradientOne = this.gradients[0];
//         this.gradientTwo = this.gradients[1];
//         this.gradient = this.$refs.canvas
//           .getContext("2d")
//           .createLinearGradient(0, 0, 0, 450);
//         this.gradient.addColorStop(0, this.gradients[0]);
//         this.gradient.addColorStop(1, this.gradients[1]);
//         chartData.datasets = chartData.datasets.map((item) => {
//           item.backgroundColor = this.gradient;
//           return item;
//         });
//       }

//       this.renderChart(
//         chartData,
//         produce(this.options || {}, (draft) => {
//           draft.scales = {
//             yAxes: [
//               {
//                 ticks: {
//                   beginAtZero: false,
//                   mirror: false,
//                   callback: function (value) {
//                     if (value >= 1000000000) {
//                       return (value / 1000000000).toFixed(2) + " B";
//                     } else if (value >= 1000000) {
//                       return (value / 1000000).toFixed(2) + " M";
//                     } else if (value >= 1000) {
//                       return (value / 1000).toFixed(2) + " K";
//                     }
//                     return value.toFixed(2) + " L";
//                   },
//                 },
//                 scaleLabel: {
//                   display: true,
//                   labelString: "Jarak Tempuh (m)", // Menambahkan label pada sumbu Y
//                   fontColor: "#333",  // Warna teks
//                   fontSize: 14,       // Ukuran font label
//                   fontStyle: "bold",  // Gaya teks
//                 },
//               },
//             ],
//             xAxes: [
//               {
//                 scaleLabel: {
//                   display: true,
//                   labelString: "Waktu (jam)", // Menambahkan label pada sumbu X
//                   fontColor: "#333",  // Warna teks
//                   fontSize: 14,       // Ukuran font label
//                   fontStyle: "bold",  // Gaya teks
//                 },
//               },
//             ],
//           };
//         })
//       );
//     }
//   },
// };

import produce from "immer";
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["chartData", "options", "gradients"],
  watch: {
    chartData: {
      deep: true, // Deteksi perubahan mendalam pada objek
      handler() {
        this.create(); // Render ulang chart ketika data berubah
      },
    },
  },
  methods: {
    update() {
      if (this.$data._chart) {
        this.$data._chart.update();
      }
    },
    create() {
      if (this.$data._chart) {
        this.$data._chart.destroy(); // Hapus chart lama
      }

      const chartData = this.chartData;

      if (this.gradients) {
        const ctx = this.$refs.canvas.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 450);
        gradient.addColorStop(0, this.gradients[0]);
        gradient.addColorStop(1, this.gradients[1]);

        chartData.datasets = chartData.datasets.map((dataset) => {
          dataset.backgroundColor = gradient;
          return dataset;
        });
      }

      const allData = chartData.datasets.flatMap((dataset) => dataset.data);
      let maxValue = Math.max(...allData);
      let minValue = Math.min(...allData);

      // Penyesuaian nilai min dan max
      if (maxValue >= 1_000_000_000) {
        maxValue += 1_000_000;
      } else if (maxValue >= 1_000_000) {
        maxValue += 50_000;
      } else if (maxValue >= 1_000) {
        maxValue += 1_000;
      } else {
        maxValue += 100;
      }

      if (minValue >= 1_000_000_000) {
        // Jika miliar, dikurang 500 juta
        minValue -= 1_000_000;
      } else if (minValue >= 1_000_000) {
        // Jika juta, dikurang 5 juta
        minValue -= 50_000;
      } else if (minValue >= 1_000) {
        // Jika ribuan, dikurang 1 ribu
        minValue -= 1_000;
      } else {
        // Jika kurang dari ribuan, dikurang 100
        minValue -= 100;
      }

      this.renderChart(
        chartData,
        produce(this.options || {}, (draft) => {
          draft.scales = {
            yAxes: [
              {
                ticks: {
                  min: minValue,
                  max: maxValue,
                  callback: function (value) {
                    if (value >= 1_000_000_000) {
                      return (value / 1_000_000_000).toFixed(2) + " M";
                    } else if (value >= 1_000_000) {
                      return (value / 1_000_000).toFixed(2) + " J";
                    } else if (value >= 1_000) {
                      return (value / 1_000).toFixed(2) + " R";
                    }
                    return value.toFixed(2);
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: "Jarak Tempuh (m)",
                  fontColor: "#333",
                  fontSize: 14,
                  fontStyle: "bold",
                },
              },
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Waktu (jam)",
                  fontColor: "#333",
                  fontSize: 14,
                  fontStyle: "bold",
                },
              },
            ],
          };
        }),
      );
    },
  },
  mounted() {
    if (this.chartData) {
      this.create();
    }
  },
};
